import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <LandingBio />
  </Layout>
)

export default IndexPage
